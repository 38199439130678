.opportunitySection {
  height: max-content;
  position: relative;
  padding: 0 24px;
  width: 100dvw;
  background: url('/images_p/HowPTWork/bg.webp');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
  overflow-x: hidden;
  overflow: hidden;

  .sectionContent {
    max-width: 1560px;
    margin: auto;
    .sectionHeader {
      text-align: center;
      label {
        color: #e6e6e6;
        text-align: center;
        text-shadow: 0px 0px 20px #45d3e8;
        font-family: var(--font-circularstdbook);
        font-size: 64px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 64px */
      }
      p {
        margin-top: 32px;
        color: #e6e6e6;
        text-align: center;
        font-family: var(--font-circularstdbook);
        font-size: 36px;
        font-style: normal;
        font-weight: 450;
        line-height: 120%; /* 43.2px */
      }
    }

    .imageContainer {
      padding: 50px;
      display: flex;
      gap: 25px;
      justify-content: center;

      img {
        flex: 1; // Allow images to grow equally
        max-width: 525px; // Set max width for three images
        width: 33.3333%;
        height: auto; // Maintain aspect ratio
        box-sizing: border-box; // Include padding/borders in width calculation
        margin: 0; // Reset margin to avoid overflow issues
      }
    }

    .sectionFooter {
      p {
        color: #e6e6e6;
        text-align: center;
        font-family: var(--font-circularstdbook);
        font-size: 36px;
        font-style: normal;
        font-weight: 450;
        line-height: 120%; /* 43.2px */
      }
    }
  }
}

@media (max-width: 1100px) {
  .opportunitySection {
    padding: 100px 24px 0 24px;
  }
}

@media (max-width: 950px) {
  .opportunitySection {
    .sectionContent {
      .imageContainer {
        flex-direction: column;
        img {
          width: 100%;
          max-width: 100%;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .opportunitySection {
    .sectionContent {
      .sectionHeader {
        label {
          font-size: 24px;
        }
        p {
          font-size: 14px;
        }
      }
      .imageContainer {
        padding: 25px 0;
      }
      .sectionFooter {
        margin: auto;
        p {
          font-size: 14px;
        }
      }
    }
  }
}
