.advisorsSection {
  height: max-content;
  position: relative;
  width: 100dvw;
  background: url('/images_p/HowPTWork/bg.webp');
  background-size: 100% 100%;
  padding-top: 100px;
  background-repeat: no-repeat;
  position: relative;
  overflow-x: hidden;
  overflow: hidden;
  padding: 50px 24px 100px 24px;

  .headerSection {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
    label {
      color: #e6e6e6;
      text-align: center;
      text-shadow: 0px 0px 20px #45d3e8;
      font-family: var(--font-circularstdmedium);
      font-size: 64px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%; /* 76.8px */
    }
  }

  .cardsSection {
    display: grid;
    gap: 30px;
    grid-template-columns: auto auto;
    justify-content: center;
    align-items: center;
    .card {
      user-select: none; /* Disable text selection */
      -webkit-user-drag: none; /* Disable image dragging on WebKit-based browsers */
      padding: 10px;
      height: 100%;
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      gap: 25px;
      padding: 1.2px;
      background-image: linear-gradient(
          217deg,
          rgb(165 239 255 / 80%),
          rgb(70 144 212 / 0%) 70.71%
        ),
        linear-gradient(
          127deg,
          rgb(152 249 255 / 80%),
          rgb(255 255 255 / 0%) 70.71%
        ),
        linear-gradient(
          336deg,
          rgb(131 80 215 / 80%),
          rgb(135 38 183 / 0%) 70.71%
        );

      background-clip: border-box; /* Ensure the gradient applies to the border area */
      border-radius: 25px; /* Optional: for rounded corners */

      .cardContent {
        position: relative;
        height: 100%;
        z-index: 1; /* Ensure the content is above the gradient */
        border-radius: 25px; /* Optional: to match rounded corners */
        background-size: 105%;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
        display: flex;
        gap: 20px;
        padding: 20px;
        background: url('/images_p/MediaWriteAboutUs/card-bg.webp');
        background-blend-mode: overlay, normal;
        background-size: 125% 200%;
        background-position: center;
        backdrop-filter: blur(40px);

        .imageContainer {
          img {
            width: 218px;
            height: 218px;
            border-radius: 25px;
            object-fit: cover;
          }
        }

        .textContainer {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          padding: 10px 0;
          gap: 30px;
          width: 100%;

          label {
            color: #fff;
            font-family: var(--font-circularstdmedium);
            font-size: 44px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%; /* 52.8px */
          }
          p {
            color: #e6e6e6;
            font-family: var(--font-circularstdmedium);
            font-size: 24px;
            font-style: normal;
            font-weight: 450;
            line-height: 120%; /* 28.8px */
          }
          .bottomText {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
          }
        }

        .socialLinksContainer {
          display: flex;
          align-items: flex-end;
          padding: 10px 0;
          a {
            height: 48px;
          }
          img {
            width: 48px;
            height: 48px;
          }
        }
      }
    }
  }
}

.text-glow {
  text-shadow: 0px 0px 20px #45d3e8;
}

@media (max-width: 1250px) {
  .advisorsSection {
    .cardsSection {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}

@media (max-width: 767px) {
  .advisorsSection {
    padding: 100px 24px 50px 24px;
    .headerSection {
      margin-bottom: 25px;
      label {
        font-size: 24px;
      }
    }
    .cardsSection {
      grid-template-columns: repeat(1, 1fr);
      gap: 15px;
      .card {
        .cardContent {
          padding: 15px;
          gap: 10px;
          .imageContainer {
            img {
              width: 84px;
              height: 84px;
              border-radius: 25px;
              object-fit: cover;
            }
          }
          .textContainer {
            gap: 12px;
            padding: 5px 5px 10px 5px;
            label {
              font-size: 18px;
            }
            p {
              font-size: 9px;
            }

            .topText {
              display: flex;
              flex-direction: column;
              gap: 10px;
            }
          }

          .socialLinksContainer {
            a {
              width: 19px;
              height: 19px;
            }
            img {
              width: 19px;
              height: 19px;
            }
          }
        }
      }
    }
  }
}
