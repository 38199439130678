.gameFi {
  width: 100dvw;
  background: url('/images_p/HowPTWork/bg.webp');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
  overflow-x: hidden;
  overflow: hidden;

  .contentWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .sectionHeader {
      margin-top: 150px;
      margin-bottom: 50px;
      max-width: 1440px;
      display: flex;
      gap: 25px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      label {
        color: #e6e6e6;
        text-align: center;
        text-shadow: 0px 0px 20px #45d3e8;
        font-family: var(--font-circularstdbook);
        font-size: 50px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%; /* 60px */
      }
      .subheader {
        label {
          color: #e6e6e6;
          text-align: center;
          text-shadow: 0px 0px 20px #45d3e8;
          font-family: var(--font-circularstdbook);
          font-size: 44px;
          font-style: normal;
          font-weight: 500;
          line-height: 120%; /* 52.8px */
        }
      }
      p {
        color: #e6e6e6;
        text-align: center;
        font-family: var(--font-circularstdbook);
        font-size: 24px;
        font-style: normal;
        font-weight: 450;
        line-height: 120%; /* 28.8px */
      }
    }

    .content {
      .articleSection {
        max-width: 1200px;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        .leftImage {
          padding-right: 75px;
        }
        .rightImage {
          padding-left: 75px;
        }
        label {
          color: #e6e6e6;
          text-shadow: 0px 0px 20px #45d3e8;
          font-family: var(--font-circularstdbook);
          font-size: 44px;
          font-style: normal;
          font-weight: 500;
          line-height: 120%; /* 52.8px */
        }
        p {
          color: #e6e6e6;
          text-align: justify;
          font-family: var(--font-circularstdbook);
          font-size: 24px;
          font-style: normal;
          font-weight: 450;
          line-height: 120%; /* 28.8px */
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .gameFi {
    .contentWrapper {
      .sectionHeader {
        margin-top: 100px;
        padding: 0 24px;
      }
      .content {
        .articleSection {
          flex-direction: column;
          align-items: center;
          justify-content: center;
          max-width: 662px;
          gap: 0;
          img {
            width: 100%;
            height: auto;
          }
          label {
            margin-top: 60px;
            margin-bottom: -30px;
            padding: 0 24px;
            text-align: center;
          }
          p {
            margin-top: -30px;
            max-width: 520px;
            padding: 0 24px;
            text-align: center;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .gameFi {
    .contentWrapper {
      .sectionHeader {
        margin-top: 50px;
        margin-bottom: 25px;
        label {
          font-size: 24px;
        }
        .subheader {
          text-align: center;
          label {
            font-size: 24px;
          }
        }
        p {
          font-size: 14px;
        }
      }
      .content {
        .articleSection {
          label {
            margin-top: 30px;
            font-size: 24px;
          }
          p {
            font-size: 14px;
          }
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .gameFi {
    .contentWrapper {
      .content {
        .articleSection {
          label {
            margin-bottom: -20px;
          }
          p {
            margin-top: -20px;
          }
        }
      }
    }
  }
}

@media (max-width: 450px) {
  .gameFi {
    .contentWrapper {
      .content {
        .articleSection {
          label {
            margin-bottom: -10px;
          }
          p {
            margin-top: -10px;
          }
        }
      }
    }
  }
}
