/* Loader Starts */
.loaderOverlay {
  position: fixed;
  width: 100%;
  height: 105%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 80000;

  cursor: pointer;
}
.loaderOverlay img {
  position: absolute;
  left: 0 !important;
  right: 0 !important;
  margin-left: auto;
  margin-right: auto;
  top: calc(50% - 75px);
  width: 100px;
  height: 100px;
  display: inline-block;
  animation: rotate 2s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media (max-width: 1150px) {
  .loaderOverlay {
    width: 100%;
    height: 105%;
    top: 70px;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
/* Loader Ends */
