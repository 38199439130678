.aiPoweredMarketUpdatesSection {
  height: max-content;
  position: relative;
  width: 100dvw;
  background: url('/images_p/HowPTWork/bg.webp');
  background-size: 100% 100%;
  padding-top: 100px;
  background-repeat: no-repeat;
  position: relative;
  overflow-x: hidden;
  overflow: hidden;

  .sectionBackground {
    width: 100%;
    background: url('/images_p/AiPoweredMarketUpdates/section-bg.webp');
    background-size: 100% 100%;
    margin-top: 60px;
    background-repeat: no-repeat;
    position: relative;
    overflow-x: hidden;
    overflow: hidden;
    width: 100%;
    padding: 80px 24px 150px 24px;
    .sectionContent {
      width: 100%;
      max-width: 1460px;
      display: flex;
      flex-direction: column;
      margin: auto;
      .sectionHeader {
        display: flex;
        flex-direction: column;
        gap: 25px;
        label {
          color: #e6e6e6;
          text-align: center;
          text-shadow: 0px 0px 20px #45d3e8;
          font-family: var(--font-circularstdbook);
          font-size: 50px;
          font-style: normal;
          font-weight: 500;
          line-height: 120%; /* 60px */
        }
        p {
          color: #e6e6e6;
          text-align: center;
          font-family: var(--font-circularstdbook);
          font-size: 24px;
          font-style: normal;
          font-weight: 450;
          line-height: 120%; /* 28.8px */
        }
      }

      .cardsWrapper {
        display: flex;
        gap: 25px;
        position: relative;
        .leftCard {
          position: absolute;
          top: -45px;
          right: -50px;
          width: 100%;
          background: url('/images_p/AiPoweredMarketUpdates/rectangle_1.webp');
          background-size: 100% 100%;
          margin-top: 100px;
          background-repeat: no-repeat;
          position: relative;
          overflow-x: hidden;
          overflow: hidden;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 50px;
          label {
            max-width: 424px;
            color: #e6e6e6;
            text-align: center;
            text-shadow: 0px 0px 20px #45d3e8;
            font-family: var(--font-circularstdbook);
            font-size: 50px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%; /* 60px */
          }
          p {
            max-width: 424px;
            color: #e6e6e6;
            text-align: center;
            text-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25);
            font-family: var(--font-circularstdbook);
            font-size: 24px;
            font-style: normal;
            font-weight: 450;
            line-height: 120%; /* 28.8px */
          }

          .clickHereButton {
            margin-top: 15px;
            margin-bottom: 15px;
            cursor: pointer;
            width: fit-content;
            border-radius: 10px;
            padding: 19px 45px;
            background: var(
              --BTN-Background,
              linear-gradient(
                180deg,
                rgba(254, 200, 241, 0.9) -22.92%,
                rgba(254, 200, 241, 0) 26.73%
              ),
              radial-gradient(
                137.13% 253.39% at 76.68% 66.67%,
                #602ea6 0%,
                #c977d6 100%
              )
            );
            background-blend-mode: overlay, normal;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: background-image 0.5s ease-in;
            cursor: pointer;

            label {
              cursor: pointer;
              color: #e6e6e6;
              font-family: var(--font-circularstdbook);
              font-size: 24px;
              font-style: normal;
              font-weight: 500;
              line-height: 120%; /* 28.8px */
              margin-bottom: 0;
            }
          }

          .clickHereButton:hover {
            background: linear-gradient(
                180deg,
                rgba(254, 200, 241, 0.9) -22.92%,
                rgba(254, 200, 241, 0) 79.14%
              ),
              radial-gradient(
                137.13% 253.39% at 76.68% 66.67%,
                #602ea6 0%,
                #c977d6 100%
              );
          }
        }

        .rightCard {
          position: absolute;
          top: 45px;
          left: -50px;
          width: 100%;
          background: url('/images_p/AiPoweredMarketUpdates/rectangle_2.webp');
          background-size: 100% 100%;
          margin-top: 100px;
          background-repeat: no-repeat;
          position: relative;
          overflow-x: hidden;
          overflow: hidden;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 50px;
          img {
            margin-top: -65px;
            margin-bottom: -50px;
          }
          label {
            color: #e6e6e6;
            text-align: center;
            text-shadow: 0px 0px 20px #45d3e8;
            font-family: var(--font-circularstdbook);
            font-size: 50px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%; /* 60px */
          }
          p {
            color: #e6e6e6;
            text-align: center;
            text-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25);
            font-family: var(--font-circularstdbook);
            font-size: 24px;
            font-style: normal;
            font-weight: 450;
            line-height: 120%; /* 28.8px */
          }
          .primeAlphaButton {
            margin-top: 15px;
            margin-bottom: 15px;
            cursor: pointer;
            width: fit-content;
            padding: 16px 45px;
            border-radius: 10px;
            border: 1px solid rgba(0, 0, 0, 0.5);
            background: linear-gradient(
                180deg,
                rgba(69, 211, 232, 0.9) -22.92%,
                rgba(152, 249, 255, 0) 26.73%
              ),
              radial-gradient(
                137.13% 253.39% at 76.68% 66.67%,
                #75bbf4 0%,
                #a9e3fa 100%
              );
            background-blend-mode: overlay, normal;

            display: flex;
            align-items: center;
            justify-content: center;
            transition: background-image 0.5s ease-in;
            cursor: pointer;

            label {
              cursor: pointer;
              color: #e6e6e6;
              font-family: var(--font-circularstdbook);
              font-size: 24px;
              font-style: normal;
              font-weight: 500;
              line-height: 120%; /* 28.8px */
              margin-bottom: 0;
            }

            img {
              margin-top: 0;
              margin-bottom: 0;
            }
          }

          .primeAlphaButton:hover {
            background: linear-gradient(
                180deg,
                rgba(213, 231, 234, 0.9) -22.92%,
                rgba(152, 249, 255, 0) 26.73%
              ),
              radial-gradient(
                137.13% 253.39% at 76.68% 66.67%,
                #75bbf4 0%,
                #a9e3fa 100%
              );
          }
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .aiPoweredMarketUpdatesSection {
    .sectionBackground {
      .sectionContent {
        .cardsWrapper {
          display: flex;
          flex-direction: column;
          align-items: center;

          .leftCard {
            max-width: 600px;
            position: relative;
            top: 0;
            right: 0;
            width: 100%;
            margin-top: 50px;
          }

          .rightCard {
            max-width: 600px;
            position: relative;
            top: 0;
            left: 0;
            width: 100%;
            margin-top: 50px;
            img {
              margin-top: -70px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .aiPoweredMarketUpdatesSection {
    padding-top: 30px;
    .sectionBackground {
      padding: 80px 24px 80px 24px;
      margin-top: 30px;
      .sectionContent {
        .sectionHeader {
          label {
            font-size: 24px;
          }
          p {
            font-size: 14px;
          }
        }

        .cardsWrapper {
          display: flex;
          flex-direction: column;
          gap: 0;
          position: relative;
          .leftCard {
            padding: 35px;
            position: relative;
            top: 0;
            right: 0;
            width: 100%;
            margin-top: 50px;
            label {
              font-size: 24px;
            }
            p {
              font-size: 14px;
            }
            img {
              width: 100%;
              height: auto;
            }
            .clickHereButton {
              padding: 19px 30px;
              label {
                font-size: 14px;
              }
            }
          }

          .rightCard {
            padding: 35px;
            label {
              font-size: 24px;
            }
            p {
              font-size: 14px;
            }
            img {
              width: 100%;
              height: auto;
              margin-top: -20px;
              margin-bottom: 0px;
            }
            .primeAlphaButton {
              img {
                max-width: 144px;
              }
            }
          }
        }
      }
    }
  }
}
