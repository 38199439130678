.becomePrimeTraderAmbassador {
  width: 100dvw;
  background: url('/images_p/HowPTWork/bg.webp');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
  overflow-x: hidden;
  overflow: hidden;

  .contentWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .sectionHeader {
      margin-top: 150px;
      margin-bottom: 50px;
      display: flex;
      gap: 25px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      label {
        color: #e6e6e6;
        text-align: center;
        text-shadow: 0px 0px 20px #45d3e8;
        font-family: var(--font-circularstdbook);
        font-size: 50px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%; /* 60px */
      }
      p {
        color: #e6e6e6;
        text-align: center;
        font-family: var(--font-circularstdbook);
        font-size: 24px;
        font-style: normal;
        font-weight: 450;
        line-height: 120%; /* 28.8px */
      }

      .getStarterdButton {
        margin-top: 15px;
        cursor: pointer;
        width: fit-content;
        border-radius: 8px;
        padding: 16px 45px;
        background: var(
          --BTN-Background,
          linear-gradient(
            180deg,
            rgba(254, 200, 241, 0.9) -22.92%,
            rgba(254, 200, 241, 0) 26.73%
          ),
          radial-gradient(
            137.13% 253.39% at 76.68% 66.67%,
            #602ea6 0%,
            #c977d6 100%
          )
        );
        background-blend-mode: overlay, normal;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: background-image 0.5s ease-in;
        cursor: pointer;

        label {
          cursor: pointer;
          color: #e6e6e6;
          font-family: var(--font-circularstdbook);
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: 120%; /* 28.8px */
          margin-bottom: 0;
        }
      }

      .getStarterdButton:hover {
        background: linear-gradient(
            180deg,
            rgba(254, 200, 241, 0.9) -22.92%,
            rgba(254, 200, 241, 0) 79.14%
          ),
          radial-gradient(
            137.13% 253.39% at 76.68% 66.67%,
            #602ea6 0%,
            #c977d6 100%
          );
      }
    }

    .content {
      .articleSection {
        max-width: 1200px;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        .leftImage {
          padding-right: 75px;
        }
        .rightImage {
          padding-left: 75px;
        }
        label {
          color: #e6e6e6;
          text-shadow: 0px 0px 20px #45d3e8;
          font-family: var(--font-circularstdbook);
          font-size: 44px;
          font-style: normal;
          font-weight: 500;
          line-height: 120%; /* 52.8px */
        }
        p {
          color: #e6e6e6;
          text-align: justify;
          font-family: var(--font-circularstdbook);
          font-size: 24px;
          font-style: normal;
          font-weight: 450;
          line-height: 120%; /* 28.8px */
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .becomePrimeTraderAmbassador {
    .contentWrapper {
      .sectionHeader {
        margin-top: 100px;
        padding: 0 24px;
      }
      .content {
        .articleSection {
          flex-direction: column;
          align-items: center;
          justify-content: center;
          max-width: 662px;
          gap: 0;
          img {
            width: 100%;
            height: auto;
          }
          label {
            margin-top: 60px;
            margin-bottom: -30px;
            padding: 0 24px;
            text-align: center;
          }
          p {
            margin-top: -30px;
            max-width: 520px;
            padding: 0 24px;
            text-align: center;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .becomePrimeTraderAmbassador {
    .contentWrapper {
      .sectionHeader {
        margin-bottom: 25px;
        label {
          font-size: 24px;
        }
        p {
          font-size: 14px;
        }
      }
      .content {
        .articleSection {
          label {
            margin-top: 30px;
            font-size: 24px;
          }
          p {
            font-size: 14px;
          }
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .becomePrimeTraderAmbassador {
    .contentWrapper {
      .content {
        .articleSection {
          label {
            margin-bottom: -20px;
          }
          p {
            margin-top: -20px;
          }
        }
      }
    }
  }
}

@media (max-width: 450px) {
  .becomePrimeTraderAmbassador {
    .contentWrapper {
      .content {
        .articleSection {
          label {
            margin-bottom: -10px;
          }
          p {
            margin-top: -10px;
          }
        }
      }
    }
  }
}
