.HowPTWorkSection {
  height: max-content;
  // min-height: 950px;
  max-height: 1078px;
  padding-top: 50px;
  padding-bottom: 50px;
  width: 100dvw;
  background: url('/images_p/HowPTWork/bg.webp');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
  overflow-x: hidden;
  overflow: hidden;

  .headerWrapper {
    width: 100%;
    padding: 0 24px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .heading {
      color: #e6e6e6;
      text-align: center;
      text-shadow: 0px 0px 20px #45d3e8;
      font-family: var(--font-circularstdbook);
      font-size: 50px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%; /* 60px */
    }

    .subheading {
      color: #e6e6e6;
      font-family: var(--font-circularstdbook);
      font-size: 24px;
      font-style: normal;
      font-weight: 450;
      line-height: 120%; /* 28.8px */
    }

    .barsWrapper {
      display: flex;
      max-width: 1280px;
      width: 50%;
      justify-content: space-around;
      gap: 25px;
      margin-top: 50px;

      .barButton {
        cursor: pointer;
        label {
          cursor: pointer;
          color: #e6e6e6;
          text-align: center;
          text-shadow: 0px 0px 20px #45d3e8;
          font-family: var(--font-circularstdbook);
          font-size: 50px;
          font-style: normal;
          font-weight: 500;
          line-height: 120%; /* 60px */
        }

        .bar {
          height: 3px;
          width: 100%;
          border-radius: 4px;
          opacity: 0;
          background: #45d3e8;
          cursor: pointer;
          // .loadingBar {
          //   height: 2px;
          //   width: 0px;
          //   background: linear-gradient(97deg, #6554c0 -0.11%, #0065ff 99.89%);
          //   border-radius: 2.14px;
          //   transition: width 12s ease-out;
          //   visibility: hidden;
          // }
        }

        .bar:hover {
          opacity: 0.8;
        }

        .activeBar {
          opacity: 1;
          // .loadingBar {
          //   visibility: visible;
          //   width: 100%;
          // }
        }
      }
    }
  }

  .topspider {
    width: 100dvw;
    background: url('/images/HowPTWork/top_spider.webp');
    position: absolute;
    top: 0;
    left: 0;
    height: 123px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: top;
  }
  .btmspider {
    width: 100dvw;
    background: url('/images/HowPTWork/btm_spider.webp');
    position: absolute;
    bottom: 0;
    left: 0;
    height: 197px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: bottom;
  }
  .leftspider {
    width: 214px;
    background: url('/images/HowPTWork/left_spider.webp');
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: left;
  }
  .rightspider {
    width: 214px;
    background: url('/images/HowPTWork/right_spider.webp');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: right;
    position: absolute;
    bottom: 0;
    right: 0;
    height: 100%;
  }
  .slider {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  .slide {
    display: flex;
    align-items: center;
    max-width: max-content;
    max-height: 728px;
    justify-content: center;
    gap: 40px;
    margin: 0 auto;
    // justify-content: space-between;

    .left {
      height: 650px;
      width: 312px;
      position: relative;
      // background-color: red;
      img {
        position: absolute;
        top: 50%;
        left: 41%;
        transform: translate(-50%, -50%);
        max-width: 100%;
        max-height: 100%;
      }
    }
    .right {
      // margin-left: 190px;
      height: 650px;
      display: flex;
      align-items: center;
      .con {
        display: flex;
        flex-direction: column;
        gap: 40px;
        .subheading {
          color: #e6e6e6;
          text-shadow: 0px 0px 20px #45d3e8;
          font-family: var(--font-circularstdbook);
          font-size: 44px;
          font-style: normal;
          font-weight: 500;
          line-height: 120%; /* 52.8px */
        }
        .content {
          color: #e6e6e6;
          text-align: justify;
          font-family: var(--font-circularstdbook);
          font-size: 24px;
          font-style: normal;
          font-weight: 450;
          line-height: 120%; /* 28.8px */
          max-width: 430px;
        }
      }
    }
  }
}

@media (max-width: 1800px) {
  .HowPTWorkSection {
    .slider {
      .loaderBar {
        top: 52.5px;
        left: 52.5%;
      }
    }
    .slide {
      max-height: 728px;
      .left {
        width: 312px;
        height: 650px;
      }
      .right {
        // margin-left: 100px;
        width: auto;
        height: 650px;
      }
    }
  }
}
@media (max-width: 1630px) {
  .HowPTWorkSection {
    padding-top: 120px;
    padding-bottom: 120px;
    .slider {
      .loaderBar {
        top: 43px;
        left: 53%;
      }
    }
    .topspider {
      height: 140px;
    }
    .btmspider {
      height: 180px;
    }
    .leftspider {
      width: 180px;
    }
    .rightspider {
      width: 180px;
    }
    .slide {
      // width: 1400px;
      max-height: 728px;
      .left {
        width: 312px;
        height: 650px;
      }
      .right {
        // margin-left: 100px;
        height: 650px;
      }
    }
  }
}

@media (max-width: 1536px) {
  .HowPTWorkSection {
    padding-top: 100px;
    padding-bottom: 100px;

    .headerWrapper {
      .barsWrapper {
        width: 60%;
      }
    }
    .slider {
      .loaderBar {
        top: 46px;
        left: 50%;
      }
    }
    .topspider {
      height: 120px;
    }
    .btmspider {
      height: 160px;
    }
    .leftspider {
      width: 170px;
    }
    .rightspider {
      width: 170px;
    }
    .slide {
      // width: 1350px;
      max-height: 728px;
      .left {
        width: 312px;
        height: 650px;
      }
      .right {
        // margin-left: 100px;
        height: 650px;
      }
    }
  }
}
@media (max-width: 1440px) {
  .HowPTWorkSection {
    padding-top: 80px;
    padding-bottom: 80px;
    .slider {
      .loaderBar {
        top: 70.5px;
        left: 53.5%;
      }
    }
    .topspider {
      height: 100px;
    }
    .btmspider {
      height: 140px;
    }
    .leftspider {
      width: 150px;
    }
    .rightspider {
      width: 150px;
    }
    .slide {
      // width: 1300px;
      max-height: 728px;
      .left {
        width: 312px;
        height: 650px;
      }
      .right {
        // margin-left: 100px;
        max-width: 40dvw;

        height: 650px;
      }
    }
  }
}
@media (max-width: 1440px) {
  .HowPTWorkSection {
    padding-top: 180px;
    .topspider {
      height: 100px;
    }
    .btmspider {
      height: 140px;
    }
    .leftspider {
      width: 150px;
    }
    .rightspider {
      width: 150px;
    }
    .slide {
      // width: 1300px;
      max-height: 728px;
      .left {
        width: 312px;
        height: 650px;
      }
      .right {
        // margin-left: 100px;

        height: 650px;
        .con {
          .heading {
            font-size: 44px;
            font-weight: 500;
            margin-bottom: 20px;
          }
          .subheading {
            font-size: 26px;
            font-weight: 500;
            margin-bottom: 15px;
          }
          ul {
            max-width: calc(100% - 20px);
            margin-left: 20px;
            li {
              font-size: 16px;
              font-weight: 500;
              line-height: 120%; /* 24px */
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1390px) {
  .HowPTWorkSection {
    .slider {
      .loaderBar {
        top: 60px;
        left: 54%;
      }
    }
  }
}

@media (max-width: 1350px) {
  .HowPTWorkSection {
    .slider {
      .loaderBar {
        top: 35px;
        left: 55%;
      }
      .right {
        .con {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          min-height: 344px;
          max-height: 345px;
        }
      }
    }
  }
}

@media (max-width: 1320px) {
  .HowPTWorkSection {
    .slider {
      .loaderBar {
        left: 55.5%;
      }
    }
  }
}

@media (max-width: 1300px) {
  .HowPTWorkSection {
    .slider {
      .loaderBar {
        top: 30px;
        left: 56%;
      }
    }
  }
}
@media (max-width: 1250px) {
  .HowPTWorkSection {
    .headerWrapper {
      .barsWrapper {
        width: 80%;
      }
    }
    .slider {
      .loaderBar {
        left: 57%;
      }
    }
  }
}
@media (max-width: 1200px) {
  .HowPTWorkSection {
    .slider {
      .loaderBar {
        left: 58%;
      }
    }
  }
}
