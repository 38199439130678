.PtWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 48px;
  flex-shrink: 0;
  margin: 0 auto;

  h1,
  h2,
  p {
    margin: 0;
  }

  .linerShade {
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgba(0, 6, 30, 0.7), rgba(0, 6, 30, 0.7)),
      linear-gradient(180deg, #00061e 0%, rgba(0, 6, 30, 0) 50.5%, #00061e 100%);
    display: flex;
  }

  .container {
    display: inline-flex;
    align-items: flex-start;
    gap: 64px;
    justify-content: center;

    width: 100%;
    padding: 100px 85px 100px 85px;

    .stats {
      display: flex;
      width: 388px;
      padding: 24px;
      justify-content: center;
      align-items: center;
      gap: 16px;

      .count {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 24px;

        .number {
          color: var(--Primary-Main, #ebebeb);
          text-align: center;
          text-shadow: 0px 0px 15px rgba(165, 234, 252, 0.6);
          font-family: var(--font-circularstdbook);
          font-size: 64px;
          font-style: normal;
          font-weight: 500;
          line-height: 125%; /* 80px */
          text-wrap: nowrap;
        }

        .title {
          color: #e6e6e6;
          text-align: center;
          font-family: var(--font-circularstdbook);
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: 120%; /* 28.8px */
          text-wrap: nowrap;
        }
      }
    }
  }
}

@media (max-width: 1900px) {
  .PtWrapper {
    zoom: 0.9;
  }
}

@media (max-width: 1700px) {
  .PtWrapper {
    zoom: 0.85;
  }
}

@media (max-width: 1600px) {
  .PtWrapper {
    zoom: 0.8;
  }
}

@media (max-width: 1500px) {
  .PtWrapper {
    zoom: 0.8;
  }
}

@media (max-width: 1490px) {
  .PtWrapper {
    zoom: 0.8;
  }
}

@media (max-width: 1440px) {
  .PtWrapper {
    zoom: 0.75;
  }
}

@media (max-width: 1400px) {
  .PtWrapper {
    zoom: 0.7;
  }
}

@media (max-width: 1380px) {
  .PtWrapper {
    zoom: 0.7;
  }
}

@media (max-width: 1280px) {
  .PtWrapper {
    zoom: 0.69;
  }
}

@media (max-width: 1250px) {
  .PtWrapper {
    zoom: 0.67;
  }
}

@media (max-width: 1180px) {
  .PtWrapper {
    zoom: 0.6;
  }
}

@media (max-width: 1000px) {
  .PtWrapper {
    zoom: 1;

    .container {
      flex-wrap: wrap;
      gap: 24px;

      padding: 20px;

      .stats {
        width: 40%;
        gap: 16px;
        padding: 14px;

        .count {
          flex-direction: column;
          .number {
            font-size: 36px;
          }

          .title {
            font-size: 20px;
          }
        }
      }
    }
  }
}
