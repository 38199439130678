.es-slides-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  gap: 1000px;
  /* overflow: hidden; */
}
.container0 {
  max-width: 1860px;
  width: 90dvw;
  margin-left: 10dvw;
  display: flex;
  align-items: center;
  position: relative;
  height: 867px;
  // .primeTraderBullets {
  //   position: absolute;
  //   top: 12%;
  // }
  .pTAVACon {
    margin-left: 57px;
    height: 867px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .title {
        color: #e6e6e6;
        font-family: var(--font-circularstdbook);
        font-size: 64px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%; /* 76.8px */
      }
      .subTitle {
        color: #e6e6e6;
        font-family: var(--font-circularstdbook);
        font-size: 20px;
        font-style: normal;
        font-weight: 450;
        line-height: 120%; /* 24px */
        width: 625px;
        margin-top: 8px;
        margin-bottom: 20px;
      }
    }
    .right {
      width: 851.286px;
      height: 767.926px;
      position: relative;
      img {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 100%;
        height: 100%;
        transform: translate(-50%, -50%);
      }
    }
  }
}
.es-slide {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0; /* Start slides as invisible */
}

.es-slider {
  margin: auto;
}

.es-slides-container > div {
  /* padding: 20px; */
  /* border: 1px solid #ccc; */
  /* margin-bottom: 10px; */
}

.es-progress-bars-container {
  display: flex;
  flex-direction: column;
  gap: 8px;

  /* custome position */
  // position: absolute;
  // top: 29.5%;
  /* left: 54%; */
  /* width: 13.5%; */
}

.es-progress-bar-container {
  /* width: 100%;
  background-color: #fff;
  border-radius: 4px; */
  height: 100px; /* Set a fixed height for the progress bar container */
  background-color: #fff;
  border-radius: 4px;
}

.es-slide-inactive {
  background: gray;
  /* Inactive color for bars not currently filling */
  border-radius: 4px;
  width: 100%;
}

.es-progress-bar {
  width: 3px;
  height: 0;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  gap: 8px;
  border-radius: 4px;
}

.es-slide-active {
  background: var(
    --Btn,
    linear-gradient(97deg, #6554c0 -0.11%, #0065ff 99.89%)
  );
  animation-name: TopToBottomfillBar;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  animation-play-state: running;
}

@keyframes TopToBottomfillBar {
  0% {
    height: 0;
  }
  100% {
    height: 100%;
  }
}

/* Add these keyframes for sliding animations */
@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideOut {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Apply these animations based on entering and exiting classes */
.es-slide-enter {
  animation: slideIn 0.9s forwards;
}

.es-slide-exit {
  animation: slideOut 0.9s forwards;
}
