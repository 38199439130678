.primeTraderAppSection {
  height: max-content;
  position: relative;
  padding: 0 24px;
  width: 100dvw;
  background: url('/images_p/HowPTWork/bg.webp');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
  overflow-x: hidden;
  overflow: hidden;
  .sectionContent {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: center;
    .leftContent {
      margin-left: -130px;
    }
    .rightContent {
      display: flex;
      flex-direction: column;
      gap: 20px;
      label {
        color: #e6e6e6;
        text-align: center;
        text-shadow: 0px 0px 20px #45d3e8;
        font-family: var(--font-circularstdbook);
        font-size: 80px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 80px */
      }
      p {
        color: #e6e6e6;
        text-align: center;
        font-family: var(--font-circularstdbook);
        font-size: 36px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%; /* 43.2px */
      }
      .linksContainer {
        margin-top: 50px;
        display: flex;
        justify-content: space-around;

        img {
          cursor: pointer;
        }
      }
    }
  }

  .mobileSectionContent {
    margin-top: 100px;
    .topSection {
      .headerSection {
        text-align: center;
        label {
          color: #e6e6e6;
          text-align: center;
          text-shadow: 0px 0px 20px #45d3e8;
          font-family: var(--font-circularstdbook);
          font-size: 80px;
          font-style: normal;
          font-weight: 500;
          line-height: 100%; /* 24px */
        }
        p {
          color: #e6e6e6;
          text-align: center;
          font-family: var(--font-circularstdbook);
          font-size: 36px;
          font-style: normal;
          font-weight: 500;
          line-height: 120%; /* 16.8px */
        }
      }

      img {
        margin-left: -100px;
        width: 100%;
        height: auto;
      }
    }

    .linksContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 30px;
      a {
        width: 100%;
        height: auto;
        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}

@media (max-width: 1300px) {
  .primeTraderAppSection {
    .sectionContent {
      .leftContent {
        margin-left: -150px;
      }
    }
  }
}

@media (max-width: 1200px) {
  .primeTraderAppSection {
    .sectionContent {
      .leftContent {
        margin-left: -180px;
      }
    }
  }
}

@media (max-width: 1150px) {
  .primeTraderAppSection {
    .sectionContent {
      .leftContent {
        margin-left: -200px;
      }
    }
  }
}

@media (max-width: 767px) {
  .primeTraderAppSection {
    .mobileSectionContent {
      margin-top: 50px;
      .topSection {
        .headerSection {
          label {
            color: #e6e6e6;
            text-align: center;
            text-shadow: 0px 0px 20px #45d3e8;
            font-family: var(--font-circularstdbook);
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%; /* 24px */
          }
          p {
            color: #e6e6e6;
            text-align: center;
            font-family: var(--font-circularstdbook);
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%; /* 16.8px */
          }
        }
        img {
          margin-left: -30px;
        }
      }
    }
  }
}
