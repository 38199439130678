.faqSection {
  color: #e6e6e6;
  font-family: var(--font-circularstdmedium);
  height: max-content;
  position: relative;
  width: 100dvw;
  background: url('/images_p/HowPTWork/bg.webp');
  background-size: 100% 100%;
  padding: 100px 24px 10px 24px;
  background-repeat: no-repeat;
  position: relative;
  overflow-x: hidden;
  overflow: hidden;
  gap: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .flexRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .cardWrapper {
    display: flex;
    height: auto;
    flex-direction: column;
    max-width: 1030px;
    width: 100%;
    flex-shrink: 0;
    gap: 25px;
    border-radius: 25px;
    border: 0.5px solid #98f9ff;
    padding: 16px 32px;

    &.openedCard {
      background: radial-gradient(
        151.92% 127.02% at 85% 21.04%,
        rgba(165, 239, 255, 0.3) 0%,
        rgba(110, 191, 244, 0.07) 77.08%,
        rgba(70, 144, 212, 0) 100%
      );
    }

    &.closedCard {
      background: radial-gradient(
        151.92% 127.02% at 85% 21.04%,
        rgba(234, 191, 255, 0.3) 0%,
        rgba(180, 105, 205, 0) 50%,
        rgba(254, 200, 241, 0) 100%
      );
    }

    .title {
      font-size: 34px;
      font-weight: 500;
      max-width: 837px;

      @media (max-width: 767px) {
        font-size: 12px;
      }
    }

    .text {
      font-size: 16px;
      font-weight: 450;

      @media (max-width: 767px) {
        font-size: 12px;
      }
    }
  }
}

@media (max-width: 767px) {
  .faqSection {
    padding: 50px 24px 10px 24px;
  }
}
