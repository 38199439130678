/*  /**=========== NavBar Desktop =========== */
.headerBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 999;
  margin-top: 50px;

  &.kolPage {
    margin-bottom: 50px;
  }

  .navBox {
    display: flex;
    align-items: center;

    ul {
      padding: 0;
      margin: 0 30px 0;
      list-style: none;
    }

    li {
      display: inline-block;
      margin: 0 30px;
    }

    a {
      font-size: 16px;
      color: #e6e6e6;
      font-family: var(--font-circularstdmedium);
      text-decoration: none;
    }

    a:hover {
      color: #949292;
    }

    .priceBox {
      font-size: 16px;
      color: #e6e6e6;
      font-family: var(--font-circularstdmedium);
      margin-right: 60px;
      vertical-align: middle;
      display: flex;
      margin-top: 10px;
    }

    .priceBox svg {
      vertical-align: middle;
      margin-right: 8px;
    }
  }
}

@media (max-width: 1024px) {
  .navBox {
    padding: 15px;
    border: 1px solid #fff;
    border-radius: 10px;
    margin: 20px;
    display: none;
  }
  .headerBox {
    position: relative;
  }
}

/* ./  /**======== NavBar Desktop =========== */

/*  /**=========== Mobile NavBar =========== */

.mobContainer {
  max-width: 100%;
  padding: 0 28px 0 7px;
}

.mobHeaderBox {
  width: 100dvw;
  z-index: 22;
  position: relative;
}

.HamburgerWrapper {
  // position
  position: absolute;
  top: 0;
  left: -9px;
  width: 100%;

  @keyframes slideInFromRight {
    0% {
      transform: translateX(100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }

  @-webkit-keyframes slideInFromRight {
    0% {
      transform: translateX(100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }

  @-moz-keyframes slideInFromRight {
    0% {
      transform: translateX(100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }

  @-o-keyframes slideInFromRight {
    0% {
      transform: translateX(100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }

  @-ms-keyframes slideInFromRight {
    0% {
      transform: translateX(100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }

  @keyframes slideInFromTop {
    0% {
      transform: translateY(-100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @-webkit-keyframes slideInFromTop {
    0% {
      transform: translateY(-100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @-moz-keyframes slideInFromTop {
    0% {
      transform: translateY(-100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @-o-keyframes slideInFromTop {
    0% {
      transform: translateY(-100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @-ms-keyframes slideInFromTop {
    0% {
      transform: translateY(-100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  .HamburgerContainer {
    // layout
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 538px;

    animation: slideInFromTop 1s ease;
    -webkit-animation: slideInFromTop 1s ease;
    -moz-animation: slideInFromTop 1s ease;
    -o-animation: slideInFromTop 1s ease;
    -ms-animation: slideInFromTop 1s ease;

    // style
    border-radius: 0px 0px 24px 24px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    background: linear-gradient(
                    0deg,
                    rgba(0, 0, 0, 0.3) 0%,
                    rgba(0, 0, 0, 0.3) 100%
    ),
    linear-gradient(180deg, #04001e 0%, rgba(0, 6, 30, 0) 100%);
    backdrop-filter: blur(7.5px);

    .navContainer {
      width: 100%;
      padding: 0 18px 0 12px;

      .nav {
        display: flex;
        justify-content: space-between;
        padding: 24px 0 16px 0;
        align-items: center;
      }
    }

    .menuContainer {
      padding-bottom: 100px;
      transition: height 0.5s ease;

      .menuBox {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 37px;
      }

      ul {
        padding: 0;
        list-style: none;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      li {
        position: relative;
        margin: 16px 0;
        min-width: 110px;
        width: fit-content;
        text-align: center;
      }

      li:first-child {
        margin-top: 0;
      }

      li::after {
        content: '';
        position: absolute;
        top: 36px;
        left: 0;
        width: 100%;
        height: 1px;
        background: linear-gradient(
                        to right,
                        rgba(255, 255, 255, 0),
                        #fff,
                        rgba(255, 255, 255, 0)
        );
      }

      a {
        font-family: var(--font-circularstdmedium);
        color: #e6e6e6;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 14px */
        text-decoration: none;
      }

      a:hover {
        color: #949292;
      }

      .priceBox {
        font-size: 14px;
        color: #fff;
        font-family: var(--font-circularstdmedium);
        vertical-align: middle;
        display: flex;
        margin-top: 17px;
        align-items: center;
        font-weight: 500;
      }

      .priceBox img {
        vertical-align: middle;
      }

      .priceBox svg {
        vertical-align: middle;
        margin-right: 8px;
      }

      .cta {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: stretch;

        .stackButton {
          display: flex;
          padding: 10px 16px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          height: 34px;
          border-radius: 8px;
          border: 1px solid #fff;
          color: #e6e6e6;
          text-align: center;
          font-family: var(--font-circularstdmedium);
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 100%; /* 14px */

          &:hover {
            opacity: 0.8;
          }
        }

        .launchButton {
          display: flex;
          padding: 10px 16px;
          justify-content: flex-end;
          align-items: center;
          gap: 10px;
          height: 34px;
          border-radius: 8px;
          background: var(
                          --BTN-Background,
                          linear-gradient(
                                          180deg,
                                          rgba(254, 200, 241, 0.9) -22.92%,
                                          rgba(254, 200, 241, 0) 26.73%
                          ),
                          radial-gradient(
                                          137.13% 253.39% at 76.68% 66.67%,
                                          #602ea6 0%,
                                          #c977d6 100%
                          )
          );
          background-blend-mode: overlay, normal;

          color: #e6e6e6;
          text-align: center;
          font-family: var(--font-circularstdmedium);
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 100%; /* 14px */

          &:hover {
            opacity: 0.8;
          }
        }
      }
    }

    .mobHeaderBox {
      width: 100dvw;
      z-index: 22;
      position: relative;
    }
  }
}

@media (max-width: 768px) {
  .nav {
    display: flex;
    justify-content: space-between;
    padding: 24px 0 16px 0;
    align-items: center;

    .cta {
      display: flex;
      flex-direction: row;
      gap: 24px;
      align-items: center;

      a {
        display: flex;
        padding: 10px 16px;
        justify-content: flex-end;
        align-items: center;
        gap: 10px;
        height: 34px;
        border-radius: 8px;
        background: var(
                        --BTN-Background,
                        linear-gradient(
                                        180deg,
                                        rgba(254, 200, 241, 0.9) -22.92%,
                                        rgba(254, 200, 241, 0) 26.73%
                        ),
                        radial-gradient(
                                        137.13% 253.39% at 76.68% 66.67%,
                                        #602ea6 0%,
                                        #c977d6 100%
                        )
        );
        background-blend-mode: overlay, normal;

        color: #e6e6e6;
        text-align: center;
        font-family: var(--font-circularstdmedium);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 14px */
      }
    }
  }
}

/* ./  /**======== Mobile NavBar =========== */
