.leadFutureFinanceSection {
  height: max-content;
  position: relative;
  padding: 0 24px;
  width: 100dvw;
  background: url('/images_p/HowPTWork/bg.webp');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
  overflow-x: hidden;
  overflow: hidden;

  .headerSection {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 252px;
    padding-top: 50px;
    flex-direction: column;
    gap: 25px;

    canvas {
      display: block;
      position: absolute;
      margin-top: -100px;
      margin-right: 10%;
      width: 100vw;
      max-width: 1560px;
      height: 100vh;
      background: transparent;
      z-index: 1;
      border: none;
    }

    label {
      color: #e6e6e6;
      text-align: center;
      text-shadow: 0px 0px 20px #45d3e8;
      font-family: var(--font-circularstdbook);
      font-size: 50px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%; /* 60px */
      z-index: 5;
    }
    p {
      color: #e6e6e6;
      text-align: center;
      font-family: var(--font-circularstdbook);
      font-size: 24px;
      font-style: normal;
      font-weight: 450;
      line-height: 120%; /* 28.8px */
    }
  }

  .content {
    display: flex;
    gap: 50px;
    justify-content: center;
    align-items: stretch;
    margin-top: 50px;
    .cardWrapper {
      display: flex;
      height: auto;
      flex-direction: column;
      padding: 10px;
      max-width: 430px;
      flex-shrink: 0;
      gap: 25px;
      padding: 1.2px;
      background-image: linear-gradient(
          217deg,
          rgb(165 239 255 / 80%),
          rgb(70 144 212 / 0%) 70.71%
        ),
        linear-gradient(
          127deg,
          rgb(152 249 255 / 80%),
          rgb(255 255 255 / 0%) 70.71%
        ),
        linear-gradient(
          336deg,
          rgb(131 80 215 / 80%),
          rgb(135 38 183 / 0%) 70.71%
        );

      background-clip: border-box; /* Ensure the gradient applies to the border area */
      border-radius: 25px; /* Optional: for rounded corners */

      .card {
        position: relative;
        height: 100%;
        z-index: 1; /* Ensure the content is above the gradient */
        border-radius: 25px; /* Optional: to match rounded corners */
        background-size: 105%;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 25px;
        padding: 35px;
        background: url('/images_p/MediaWriteAboutUs/card-bg.webp');
        background-blend-mode: overlay, normal;
        background-size: 125% 200%;
        background-position: center;
        backdrop-filter: blur(40px);

        label {
          color: #e6e6e6;
          text-align: center;
          text-shadow: 0px 0px 20px #45d3e8;
          font-family: var(--font-circularstdbook);
          font-size: 44px;
          font-style: normal;
          font-weight: 500;
          line-height: 120%; /* 52.8px */
        }

        p {
          color: #e6e6e6;
          text-align: center;
          font-family: var(--font-circularstdbook);
          font-size: 24px;
          font-style: normal;
          font-weight: 450;
          line-height: 120%; /* 28.8px */
        }
      }
    }
  }

  .sectionFooter {
    margin-top: 50px;
    text-align: center;
    label {
      color: #e6e6e6;
      text-align: center;
      text-shadow: 0px 0px 20px #45d3e8;
      font-family: var(--font-circularstdbook);
      font-size: 36px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%; /* 43.2px */
    }
  }
}

@media (max-width: 1450px) {
  .leadFutureFinanceSection {
    .content {
      flex-direction: column;
      align-items: center;
    }
  }
}

@media (max-width: 767px) {
  .leadFutureFinanceSection {
    .graphLine {
      background-size: 100% 252px;
    }
    .headerSection {
      background-size: 110% 252px;
      label {
        font-size: 28px;
      }
      p {
        font-size: 14px;
      }
    }
    .content {
      margin-top: 15px;
      .cardWrapper {
        .card {
          padding: 25px;
          gap: 15px;
          label {
            font-size: 24px;
          }
          p {
            font-size: 14px;
          }
        }
      }
    }

    .sectionFooter {
      label {
        font-size: 14px;
      }
    }
  }
}
