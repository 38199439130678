.ourPartners {
  height: max-content;
  position: relative;
  padding: 100px 24px 100px 24px;
  width: 100dvw;
  background: url('/images_p/HowPTWork/bg.webp');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
  overflow-x: hidden;
  overflow: hidden;

  .sectionHeader {
    width: 100%;
    text-align: center;
    margin-bottom: 50px;
    label {
      color: #e6e6e6;
      text-align: center;
      text-shadow: 0px 0px 20px #45d3e8;
      font-family: var(--font-circularstdbook);
      font-size: 64px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%; /* 76.8px */
    }
  }

  .sectionContent {
    max-width: 1560px;
    margin: auto;
    display: grid;
    gap: 25px 5px;
    align-items: center;
    justify-content: center;
    grid-template-columns: repeat(4, 1fr);

    img {
      align-self: center;
      justify-self: center;
    }
  }
}

@media (max-width: 1250px) {
  .ourPartners {
    .sectionContent {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

@media (max-width: 850px) {
  .ourPartners {
    .sectionContent {
      img {
        width: 80%;
        height: auto;
      }
    }
  }
}

@media (max-width: 767px) {
  .ourPartners {
    padding: 10px 24px 50px 24px;
    .sectionHeader {
      label {
        font-size: 24px;
      }
    }
  }
}
