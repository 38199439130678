.es-slides-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  gap: 1000px;

  /* Ensure the min highet */
  /* min-height: 722px;
  height: 100dvh; */
  overflow: hidden;
}

.es-slide {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0; /* Start slides as invisible */
}

.es-slider {
  margin: 30px;
}

.es-slides-container > div {
  /* padding: 20px; */
  /* border: 1px solid #ccc; */
  /* margin-bottom: 10px; */
}

.es-progress-bars-container {
  display: flex;
  /* flex-direction: column; */
  gap: 23px;
  /* Fix slider responsive for progressBar */
  position: absolute;
  width: 360px;
  z-index: 1; /* Set a higher z-index to ensure it stays above other elements */
}

.es-progress-bar-container {
  width: 100%;
  background-color: #fff;
  border-radius: 4px;

  /* curssor style */
  cursor: pointer;
}

@media (hover: hover) and (pointer: fine) {
  .es-progress-bar-container:hover {
    .es-progress-bar {
      background: var(
        --Btn,
        linear-gradient(97deg, #f3f3f3 -0.11%, #f3f3f3 99.89%)
      );
    }
  }
}

.es-progress-bar {
  width: 0%;
  height: 3px;
  display: flex;
  align-items: flex-start;
  gap: 8px;
  border-radius: 4px;
}

.es-slide-active {
  /* background-color: green;  */
  /* Active color */
  background: var(
    --Btn,
    linear-gradient(97deg, #6554c0 -0.11%, #0065ff 99.89%)
  );
  animation-name: fillBar;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  animation-play-state: running;
}

.es-slide-inactive {
  background: gray;
  /* Inactive color for bars not currently filling */
  border-radius: 4px;
  width: 100%;
}

@keyframes fillBar {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

/* Add these keyframes for sliding animations */
@keyframes slideIn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideOut {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

/* Apply these animations based on entering and exiting classes */
.es-slide-enter {
  animation: slideIn 0.8s forwards;
}

.es-slide-exit {
  animation: slideOut 0.8s forwards;
}

/*  /**=========== Hero Slider Mobile =========== */
@media (max-width: 768px) {
  .es-progress-bars-container {
    display: flex;
    /* flex-direction: column; */
    gap: 23px;
    /* Fix slider responsive for progressBar */
    position: absolute;
    width: 310px;
    z-index: 1; /* Set a higher z-index to ensure it stays above other elements */
  }

  .es-progress-bar {
    width: 0%;
    height: 3px;
    display: flex;
    align-items: flex-start;
    gap: 8px;
    border-radius: 4px;
  }

  .es-slide-active {
    /* background-color: green;  */
    /* Active color */
    background: var(
      --Btn,
      linear-gradient(97deg, #6554c0 -0.11%, #0065ff 99.89%)
    );
    animation-name: fillBar;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    animation-play-state: running;
  }

  .es-slide-inactive {
    background: gray;
    /* Inactive color for bars not currently filling */
    border-radius: 8px;
    width: 100%;
  }
}
/* ./  /**======== Hero Slider Mobile =========== */
