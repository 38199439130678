.aiPoweredTrading {
  height: max-content;
  position: relative;
  padding: 150px 24px 50px 24px;
  width: 100dvw;
  background: url('/images_p/HowPTWork/bg.webp');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
  overflow-x: hidden;
  overflow: hidden;
  .sectionContent {
    max-width: 1440px;
    margin: auto;
    display: flex;
    gap: 50px;
    justify-content: center;
    align-items: center;
    .leftContent {
      flex: 1;
      margin-bottom: auto;
      display: flex;
      flex-direction: column;
      gap: 25px;
      padding: 30px 0;
      label {
        color: #e6e6e6;
        text-shadow: 0px 0px 20px #45d3e8;
        font-family: var(--font-circularstdbook);
        font-size: 64px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%; /* 76.8px */
      }
      p {
        color: #e6e6e6;
        text-align: justify;
        font-family: var(--font-circularstdbook);
        font-size: 24px;
        font-style: normal;
        font-weight: 450;
        line-height: 120%; /* 28.8px */
      }
    }
    .rightContent {
      flex: 1;
      img {
        width: 100%;
        height: auto;
      }
    }
  }
}

@media (max-width: 900px) {
  .aiPoweredTrading {
    padding: 75px 24px 20px 24px;
    .sectionContent {
      flex-direction: column;
      .leftContent {
        label {
          text-align: center;
        }
        p {
          text-align: center;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .aiPoweredTrading {
    padding: 35px 24px 10px 24px;
    .sectionContent {
      gap: 25px;
      .leftContent {
        label {
          font-size: 24px;
        }
        p {
          font-size: 14px;
        }
      }
    }
  }
}
