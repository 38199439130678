.roadmap-section_mob {
  background: url(/images_p/main-section-bg-test.webp) no-repeat top right;
  background-size: cover;
  // height: 100dvh;
  min-height: max-content;
  position: relative;

  .liner-shade {
    // padding: 200px 0 80px;
    width: 100%;
    height: 100%;
    // min-height: 100dvh;

    background: linear-gradient(0deg, rgba(0, 6, 30, 0.7), rgba(0, 6, 30, 0.7)),
      linear-gradient(180deg, #00061e 0%, rgba(0, 6, 30, 0) 50.5%, #00061e 100%);
    display: flex;
    justify-content: center;
    position: relative;
    width: 100dvw;
  }

  svg rect.blurred {
    filter: url(#blur);
  }

  @keyframes slideIn {
    0% {
      opacity: 0;
      backdrop-filter: blur(0);
    }
    100% {
      opacity: 1;
      backdrop-filter: blur(15px);
    }
  }
  @keyframes backdropani {
    0% {
    }
    100% {
    }
  }

  .content {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 327px;

    // layout adjustment
    margin-right: -10px;

    .mainHeading {
      text-align: center;
      h2 {
        font-family: var(--font-circularstdbook);
        color: #e6e6e6;
        text-align: center;
        font-size: 36px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%; /* 43.2px */
      }
    }

    // buttons
    .rowBtns {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      position: relative;
      z-index: 22;

      .RoadMapBtnSelected {
        background: url('/images/roadmap/Modal/btnBorderColor.webp');
        transition: background-image 150ms ease-in-out;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        width: 62px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;

        font-family: var(--font-poppins);
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        text-align: center;
        color: rgba(230, 230, 230, 1);
        border: none;
        border-radius: 8px;
      }

      .RoadMapBtnHighlight {
        background: rgba(255, 255, 255, 1);
        cursor: pointer;
        transition: background-color 150ms ease-out;

        width: 62px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;

        font-family: var(--font-poppins);
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
        text-align: center;
        color: rgba(4, 2, 23, 1);

        border: 2px solid rgba(255, 255, 255, 1);
        border-radius: 8px;
      }

      .RoadMapBtnHighlight:hover {
        background: linear-gradient(
            0deg,
            rgba(174, 122, 196, 0.2) 0%,
            rgba(174, 122, 196, 0.2) 100%
          ),
          #fff;
      }

      .RoadMapBtnHighlight:active {
        background: linear-gradient(
            0deg,
            rgba(174, 122, 196, 0.4) 0%,
            rgba(174, 122, 196, 0.4) 100%
          ),
          #fff;
      }

      .RoadMapBtnOutline {
        background-color: transparent;
        cursor: pointer;
        transition: background-color 150ms ease-out;
        width: 62px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: var(--font-poppins);
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        text-align: center;
        color: rgba(230, 230, 230, 1);
        border: 2px solid rgba(255, 255, 255, 1);
        border-radius: 8px;
        opacity: 0.5;
        cursor: no-drop;
        border-color: lightgray;
      }

      .RoadMapBtnOutline:hover {
        background-color: rgba(174, 122, 196, 0.2);
      }

      .RoadMapBtnOutline:active {
        background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.2) 0%,
            rgba(0, 0, 0, 0.2) 100%
          ),
          rgba(174, 122, 196, 0.2);
      }
    }

    // grid boxes

    .wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 0px 16px;
      align-content: flex-start;
      width: 100dvw;
      align-items: center;
      justify-items: center;

      // reset layout adjustment on gird
      margin-right: 10px;

      div {
        // border: 1px solid red;
      }

      .boxWrapper {
        position: relative;
      }

      .box {
        display: flex;
        width: 167px;
        height: 100%;
        padding: 45px 16px 16px 16px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 16px;
        flex-shrink: 0;
        border-radius: 24px;
        border: 2px solid rgba(255, 255, 255, 0.3);
        background: rgba(13, 7, 41, 0.5);
        backdrop-filter: blur(9px);
        margin-top: 100px;

        img {
          position: absolute;
          z-index: 2;
          top: 0;
          left: 50%;
          // transform: translateX(-50%) translateY(-66%); with svg
          transform: translateX(-49%) translateY(-55%); // with png
        }

        h3,
        h4 {
          margin: 0;
          padding: 0;
          color: #fff;
          text-align: center;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }

        h3 {
          font-size: 20px;
        }

        h4 {
          font-size: 14px;
        }
      }
    }
  }

  .modal-wrapper {
    width: 100%;
    height: 100vh; /* Full viewport height */
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999; /* Ensure modal is on top */
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(14px);
    background: rgba(15, 15, 15, 0);
    display: flex;
  }

  .modal {
    border-radius: 24px;
    border: 2px solid rgba(255, 255, 255, 0.1);
    background: rgba(13, 7, 41, 0.8);
    backdrop-filter: blur(20px);

    display: flex;
    max-width: 90vw;
    width: 308px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;

    padding: 45px 16px 16px 16px;

    border-radius: 24px;
    border: 2px solid rgba(255, 255, 255, 0.1);

    animation: slideIn 1s ease-in-out forwards;

    h1 {
      color: #fff;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding: 0;
      margin: 0;
    }

    h2 {
      color: #fff;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding: 0;
      margin: 0;
    }

    img {
      position: absolute;
      top: -197px;
      width: 100%;
      text-align: center;
    }

    .modal-body {
      padding-top: 10px;
    }

    ul {
      list-style-type: disc;
    }

    li {
      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: 120%; /* 16.8px */
      margin-bottom: 16px;
      margin-left: 15px;
    }

    li::before {
      content: '\2022'; /* Bullet character */
      color: #fff;
      font-size: 14px; /* Adjust as needed */
      line-height: 120%; /* Adjust as needed */
      position: absolute;
      left: 15px; /* Adjust distance from text */
    }
  }

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh; /* Full viewport height */
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9998; /* Place overlay below modal */
  }

  .modal-header {
    display: flex;
    justify-content: flex-end;
    font-size: 25px;
  }
}

.text-glow {
  text-shadow: 0px 0px 25px #91ebff;
}